.skillsContainer {
    text-align: center;
    display: flex;
    background-color: #161B33;
    flex-direction: column;
    padding-bottom: 40px;
    padding-top: 22px;
    padding-left: 22px;
    padding-right: 22px;
}

.skillsTitle {
    font: 18px/24px;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #F1DAC4;
}

.skills-icon {
    color: #F1DAC4;
}

.skills-label {
    color: #F1DAC4;
}

.skills-ul {
    list-style-type: none;
    padding: 20px;
    margin: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.skills-li {
    margin: 20px;
}