.headerContainer {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: left;
    height: 90vh;
    background-color: #F1DAC4;
    /* flex-direction: column; */
}

.titleContainer {
    color: #161B33;
    margin-left: 40px;
}

.title {
    font-size: 3rem;
    text-align: left;
    padding: 0px;
    margin: 0px;
}

.github-corner:hover .octo-arm {
    animation: octocat-wave 560ms ease-in-out;
}

@keyframes octocat-wave {
    0%, 100% {
        transform: rotate(0);
   }
    20%, 60% {
        transform: rotate(-25deg);
   }
    40%, 80% {
        transform: rotate(10deg);
   }
}
@media (max-width: 500px) {
    .github-corner:hover .octo-arm {
        animation: none;
   }
    .github-corner .octo-arm {
        animation: octocat-wave 560ms ease-in-out;
   }
}