.experienceContainer {
    /* text-align: center; */
    display: flex;
    background-color: #F1DAC4;
    flex-direction: column;
    padding-bottom: 40px;
    padding-top: 22px;
}

.experienceCardHeader {
    text-align: left;
}

.experience-icon {
	font-size: 300%;
}

@media only screen and (max-width: 1170px) {
    .experience-icon {
        font-size: 170%;
   }
}

.experience-icon-container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}