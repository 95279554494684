.projectsContainer {
    text-align: center;
    display: flex;
    background-color: #A69CAC;
    flex-direction: column;
    padding-bottom: 40px;
    padding-top: 22px;
}

.carousel-container-custom {
    padding: 12px;
}

.projectTitle {
    font: 18px/24px;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #161B33;
}

.projectDetailTitle {
    font-size: 1.75rem;
    color: #161B33;
    margin: 20px 0 8px;
}

.modal-description {
	text-align: justify;
	margin-bottom: 20px;
	font-size: 12px;
}

.skill-icon {
    color: #161B33;
    margin-right: 20px;
}